import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`
const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Atlassian Confluence?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Atlassian Confluence is a content collaboration tool used to collaborate and share knowledge efficiently. This tool allows users to write, edit, get work done and comment within the confluence interface."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Do We Need Confluence?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "With Confluence, your team can capture project requirements, assign tasks to users, have clarity in project scope, access project scope, enjoy easy reporting and manage many calendars at once. Codestaff can help you"
        }
    }
}

const AtlassianConfluence = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Atlassian Confluence Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Atlassian Confluence Developers. Top
                                    companies and start-ups choose Codestaff Atlassian Confluence experts
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Atlassian Confluence Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE ATLASSIAN CONFLUENCE DEVELOPERS' banner='/atlassian-confluence.png' bannerAlt='atlassian confluence banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default AtlassianConfluence