import React from 'react'
import Layout from '../components/layout'
import AtlassianConfluence from '../components/categories/atlassian-confluence'
import Seo from '../components/seo'

const AtlassianConfluencePage = ({ location }) => {
  return (
    <Layout location={location}>
      <AtlassianConfluence />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Atlassian Confluence Freelancers | Codestaff'
  const description =
    'Hire the best Atlassian Confluence freelancers at Codestaff. Get the top 1% of Atlassian Confluence professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default AtlassianConfluencePage
